import { Role } from './types'

const admin = [Role.Administrator]
const sorting = [...admin, Role.Sorter]
const polishing = [...admin, Role.Polisher]
const gemFeedback = [...admin, Role.GemFeedbackHandler]

export const roleGroups = {
  admin,
  sorting,
  polishing,
  gemFeedback,
}
