<template lang="pug">
.index
  v-row
    //- Errors
    v-col(cols='12' v-if='errors.length')
      v-alert(
        v-for='(error, index) in errors',
        :key='`${error.message}${index}`'
        type='error'
      )
        | {{ error.message }}

    //- Widgets
    //- Dynamic component: To add more, include other components in the widgets array
    component(
      v-for='({ component, props }, i) in widgets',
      :key='i'
      v-bind='props',
      :is='component'
    )
</template>

<script lang="ts" setup>
import { fetchOrderSummary } from '@/api/orders'
import type { Role } from '@/auth/types'
import WidgetPolishing from '@/components/WidgetPolishing.vue'
import WidgetResource, {
  type Props as WidgetResourceProps,
} from '@/components/WidgetResource.vue'
import WidgetSorting from '@/components/WidgetSorting.vue'

useHead({
  title: 'Dashboard',
})

type WidgetItem = {
  component: any
  roleGroup: Role | Role[]
  props?: any
}

const { hasAccess, roleGroups } = useAuth()

const widgets = computed((): any[] => {
  return widgetItems.value.filter(item => hasAccess(item.roleGroup))
})

const widgetItems = shallowRef<WidgetItem[]>([
  { component: WidgetSorting, roleGroup: roleGroups.sorting },
  { component: WidgetPolishing, roleGroup: roleGroups.polishing },
  {
    component: WidgetResource,
    roleGroup: roleGroups.admin,
    props: {
      name: 'Orders',
      route: {
        name: 'activity.index',
        query: { 'active-panel': '0', 'filter-by': '0' },
      },
      summaryFn: fetchOrderSummary,
      summaryMap: {
        erp_import_started: {
          color: 'blue',
          filter: 0,
          label: 'NetSuite Import Started',
        },
        erp_import_time_out: {
          color: 'warning',
          filter: 3,
          label: 'NetSuite Import Timed Out',
        },
        erp_import_error: {
          color: 'error',
          filter: 1,
          label: 'NetSuite Import Error',
        },
        erp_import_complete: {
          color: 'success',
          filter: 0,
          label: 'NetSuite Import Complete',
        },
      },
    } satisfies WidgetResourceProps,
  },
])

const errors = ref([] as Error[])
</script>
